import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { SingleProductData } from "../../Config/SingleProductData";
import GridViewComponent from "../../Components/GridViewComponent/GridViewComponent";
import FsLightbox from "fslightbox-react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constants/Constants";
import axios from "axios";
import "./SingleProductScreen.css";
import Loader from "../../Components/Loader/Loader";
import { ImCart } from "react-icons/im";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { populateCart, addToCart } from "../../ReduxStore/Store";
import { useTranslation } from "react-i18next";
import { checkToken } from "../../Components/Custom Hooks/checkToken";
import Dropdown from "react-bootstrap/Dropdown";
import { setProfile } from "../../ReduxStore/Store";
import { clearToken } from "../../ReduxStore/Store";
import { clearList } from "../../ReduxStore/Store";
import { clearShippingAddress } from "../../ReduxStore/Store";
import { clearBillingAddress } from "../../ReduxStore/Store";
import { emptyCart } from "../../ReduxStore/Store";
import { clearUndo } from "../../ReduxStore/Store";
import { clearProfile } from "../../ReduxStore/Store";
import Cookies from "js-cookie";
import { setShowMsgTrue } from "../../ReduxStore/Store";
import { useNavigate } from "react-router-dom";
import { useTokenValid } from "../../Components/Custom Hooks/useTokenValid";
import { Helmet } from "react-helmet";

const ProductDisplayScreen = () => {
  const { t } = useTranslation();
  const { product_slug } = useParams();
  //   const categoryName = category_name.split("-").join(" ");

  // console.log(product_id);

  const [product, setProduct] = useState(undefined);
  const [productInStock, setProductInStock] = useState(true);
  const [productType, setProductType] = useState(undefined);
  const [productVariants, setProductVariants] = useState(undefined);
  const [selectedVariant, setSelectedVariant] = useState(undefined);
  const [cartData, setCartData] = useState(undefined);
  const [relatedProducts, setRelatedProducts] = useState(undefined);
  const [addedProductMsg, setAddedProductMsg] = useState(undefined);

  const [value0, setValue0] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState([]);
  const [value6, setValue6] = useState([]);
  const [value7, setValue7] = useState([]);
  const [value8, setValue8] = useState([]);

  const [showMsg, setShowMsg] = useState(false);
  const [backgroundPos, setBackgroundPos] = useState(`0% 0%`);
  const [toggler, setToggler] = useState(false);
  const [qty, setQty] = useState(1);
  const token = useSelector((state) => state.auth.token);
  const cart = useSelector((state) => state.cart);
  const lang = useSelector((state) => state.lang.ELPiLang);
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useTokenValid(auth);

  const getProductsData = (pathComponent) => {
    axios
      .get(`${BASE_URL}/${pathComponent}`)
      .then((res) => {
        setProduct(res?.data?.data);
        setProductInStock(res?.data?.data?.in_stock);
        setProductType(res?.data?.data?.type);
        if (res?.data?.data?.product_fields_0_value) {
          setValue0(res?.data?.data?.product_fields_0_value.split("_"));
        }
        if (res?.data?.data?.product_fields_1_value) {
          setValue1(res?.data?.data?.product_fields_1_value.split("_"));
        }
        if (res?.data?.data?.product_fields_2_value) {
          setValue2(res?.data?.data?.product_fields_2_value.split("_"));
        }
        if (res?.data?.data?.product_fields_3_value) {
          setValue3(res?.data?.data?.product_fields_3_value.split("_"));
        }
        if (res?.data?.data?.product_fields_4_value) {
          setValue4(res?.data?.data?.product_fields_4_value.split("_"));
        }
        if (res?.data?.data?.product_fields_5_value) {
          setValue5(res?.data?.data?.product_fields_5_value.split("_"));
        }
        if (res?.data?.data?.product_fields_6_value) {
          setValue6(res?.data?.data?.product_fields_6_value.split("_"));
        }
        if (res?.data?.data?.product_fields_7_value) {
          setValue7(res?.data?.data?.product_fields_7_value.split("_"));
        }
        if (res?.data?.data?.product_fields_8_value) {
          setValue8(res?.data?.data?.product_fields_8_value.split("_"));
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getRelatedProductsData = (pathComponent) => {
    axios
      .get(`${BASE_URL}/${pathComponent}`)
      .then((res) => {
        setRelatedProducts(res?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (lang === "fr") {
      getProductsData(`products-slug/${product_slug}?locale=fr`);
      getRelatedProductsData(`related-products?id=${product_slug}&locale=fr`);
    } else {
      getProductsData(`products-slug/${product_slug}`);
      getRelatedProductsData(`related-products?id=${product_slug}`);
    }
    setShowMsg(false);
    setQty(1);
  }, [lang, product_slug]);

  const decQty = () => {
    if (qty >= 2) {
      setQty(qty - 1);
    }
  };

  const incQty = () => {
    setQty(Number(qty) + 1);
  };

  const handleChange = (e) => {
    if (e.target.value > -1) setQty(e.target.value);
  };

  const addToCartHandler = () => {
    // console.log(token, product.id, qty);
    if (qty) {
      // dispatch(addToCart({ ...product, quantity: qty }));
      if (token) {
        if (selectedVariant) {
          const config = {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const params = {
            product_id: product.id,
            quantity: qty,
            selected_configurable_option: selectedVariant.id,
          };
          axios
            .post(
              `${BASE_URL}/checkout/cart/add/${selectedVariant.id}?token=true`,
              params,
              config
            )
            .then((res) => {
              setCartData(res?.data?.data?.items);
              // console.log(res.data.data.items);
              // console.log('called', res);
              setAddedProductMsg(selectedVariant.name);
            })
            .catch((error) => {
              // console.log(error);
            });
        } else {
          const config = {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const params = {
            product_id: product.id,
            quantity: qty,
            token: true,
          };
          axios
            .post(`${BASE_URL}/checkout/cart/add/${product.id}`, params, config)
            .then((res) => {
              setCartData(res?.data?.data?.items);
              // console.log(res?.data?.data?.items);
              setAddedProductMsg(product.name);
            })
            .catch((error) => {
              // console.log(error);
            });
        }
      } else {
        if (selectedVariant) {
          dispatch(
            addToCart({
              product: product,
              id: selectedVariant.id,
              quantity: qty,
              name: selectedVariant.name,
              variant: true,
            })
          );
          setAddedProductMsg(selectedVariant.name);
        } else {
          dispatch(
            addToCart({
              product: product,
              id: product.id,
              quantity: qty,
              name: product.name,
              variant: false,
            })
          );
          setAddedProductMsg(product.name);
        }
      }

      setShowMsg(true);
    }
  };
  const handleMouseOverImg = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPos(`${x}% ${y}%`);
    // console.log(backgroundPos);
  };

  // console.log(product);
  // console.log(cart);
  // console.log(cartData);

  useEffect(() => {
    if (cartData) {
      dispatch(populateCart(cartData));
    }
  }, [cartData]);

  useEffect(() => {
    if (productType === "configurable") {
      setProductVariants(product?.variants);
      // console.log(product?.variants);
    }
  }, [product]);

  useEffect(() => {
    if (productVariants) {
      setSelectedVariant(productVariants[0]);
    }
  }, [productVariants]);

  // console.log(cart);

  const input = document.querySelector(".qty-num");
  if (input) {
    input.addEventListener("blur", () => {
      const currentValue = Number(input.value);
      if (!currentValue) {
        setQty(1);
      }
    });
  }

  const handleSelectVariant = (variant) => {
    // console.log(variant);
    setSelectedVariant(variant);
  };

  return (
    <>
      {!product ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{`${product?.name} - European Lorry Parts Industry`}</title>
            <meta></meta>
          </Helmet>
          <div className="page-container container">
            <div className="row mt-5">
              {addedProductMsg ? (
                !showMsg ? null : (
                  <div className="mb-5">
                    <div className="msg-bar d-flex justify-content-between align-items-center py-2 px-3">
                      <div className="d-flex flex-row">
                        <div
                          className="msg-bar-text"
                          style={{ color: "#808080" }}
                        >{`"${addedProductMsg}" ${t(
                          "product_added_msg"
                        )}`}</div>
                      </div>
                      <Link
                        to="/cart"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className="shortcut-btn d-flex flex-row">
                          <ImCart className="me-2 mt-1" />
                          <div>{`${t("View Cart")}`}</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              ) : null}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 product-img-container">
                <figure
                  onClick={() => setToggler(!toggler)}
                  onMouseMove={handleMouseOverImg}
                  style={{
                    backgroundImage: `url(${product?.images[0]?.original_image_url})`,
                    backgroundPosition: `${backgroundPos}`,
                  }}
                >
                  <img
                    className="product-img"
                    src={product?.images[0]?.original_image_url}
                    alt={product[0]?.description}
                  />
                </figure>
              </div>
              <FsLightbox
                toggler={toggler}
                sources={[product?.images[0]?.original_image_url]}
              />

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-3 py-2 ELPi-text-color">
                <div>
                  <div className="h2">
                    <b>ELPI No : </b>
                    <p style={{ display: "inline-block", fontWeight: "350" }}>
                      {product?.name}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <p
                    className="product-field-title New_Line_Height"
                    style={{ fontWeight: "600" }}
                  >
                    {`${t("Description")} :`}
                  </p>
                  <p className="ms-2 New_Line_Height">{product?.description}</p>
                </div>
                {value0.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_0_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height responsive_line_height">
                      {value0.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value1.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_1_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value1.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value2.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_2_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value2.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value3.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_3_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value3.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value4.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_4_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value4.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value5.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_5_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value5.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value6.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_6_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value6.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value7.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_7_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value7.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {value8.length === 0 ? null : (
                  <div className="d-flex flex-row">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`${product?.product_fields_8_title} :`}
                    </p>
                    <span className="d-flex flex-column ms-2 New_Line_Height">
                      {value8.map((value, i) => (
                        <p key={i}>{value}</p>
                      ))}
                    </span>
                  </div>
                )}
                {productVariants ? (
                  <div className="d-flex flex-row my-4">
                    <p
                      className="product-field-title New_Line_Height"
                      style={{ fontWeight: "600" }}
                    >
                      {`Size :`}
                    </p>
                    <div className="d-flex flex-column">
                      <Dropdown className="d-inline">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true ELPi-text-color h5"
                          variant="secondary"
                        >
                          {selectedVariant
                            ? selectedVariant?.name.includes("-")
                              ? selectedVariant?.name.split("-")[1]?.trim()
                              : selectedVariant.name
                            : null}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {productVariants.map((variant) => (
                            <Dropdown.Item
                              key={variant.id}
                              onClick={() => handleSelectVariant(variant)}
                            >
                              {variant?.name
                                ? variant?.name.includes("-")
                                  ? variant?.name.split("-")[1]?.trim()
                                  : variant.name
                                : null}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div
                        className="shortcut-btn"
                        onClick={() => setSelectedVariant(productVariants[0])}
                      >
                        {t("clear")}
                      </div>
                    </div>
                  </div>
                ) : null}
                {productInStock ? (
                  <div className="d-flex">
                    <div className="quantity-field">
                      <button className="val-btn dec-btn" onClick={decQty}>
                        -
                      </button>
                      <input
                        type="number"
                        className="qty-num"
                        value={qty}
                        onChange={handleChange}
                      />
                      <button className="val-btn inc-btn" onClick={incQty}>
                        +
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={addToCartHandler}
                        className="btn basic-btn mx-3"
                        type="submit"
                      >
                        {t("add_to_enquiry")}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="ELPi-text-color h5 w-100 d-flex justify-content-center align-items-center"
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    <div
                      className="py-4 px-4"
                      style={{
                        borderTop: "1px solid #494949",
                        borderBottom: "1px solid #494949",
                      }}
                    >
                      {t("OUT OF STOCK")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="my-5">
              {!relatedProducts ? null : (
                <>
                  {relatedProducts.length === 0 ? null : (
                    <div className="d-flex justify-content-left">
                      <p
                        className="h3 ELPi-text-color my-3"
                        style={{ fontWeight: "400" }}
                      >
                        {t("related_products")}
                      </p>
                    </div>
                  )}
                  <div className="row">
                    {relatedProducts.map((relatedProduct) => (
                      <div
                        key={relatedProduct.id}
                        className="col-lg-4 col-md-6 col-sm-6 col-12"
                      >
                        <Link
                          to={
                            "/product/" +
                            "specific" +
                            "/" +
                            relatedProduct.url_key
                          }
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <GridViewComponent item={relatedProduct} />
                        </Link>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDisplayScreen;
