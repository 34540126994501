import React, { useEffect, useState } from 'react';
import CartCard from '../../Components/CartCard/CartCard';
import { Link } from 'react-router-dom';
// import { cartProductsData } from "../../Config/CartProductsData";
import { Modal } from 'react-bootstrap';
import EnquiryForm from '../../Components/EnquiryForm/EnquiryForm';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import { useSelector } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import { RxCross1 } from 'react-icons/rx';
import { ImCart } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import { addToCart, updateQuantity } from '../../ReduxStore/Store';
import { populateCart } from '../../ReduxStore/Store';
import { clearList } from '../../ReduxStore/Store';
import './CartScreen.css';
import { useTranslation } from 'react-i18next';
import { checkToken } from '../../Components/Custom Hooks/checkToken';
import { useTokenValid } from '../../Components/Custom Hooks/useTokenValid';
import { Helmet } from 'react-helmet';

const CartScreen = () => {
  const { t } = useTranslation();
  const [cartData, setCartData] = useState(undefined);
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);
  const [showUndoMsg, setShowUndoMsg] = useState(false);
  const [showUpdMsg, setShowUpdMsg] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const undoProduct = useSelector((state) => state.undo);
  const reduxCartItems = useSelector((state) => state.cart.items);
  const total = useSelector((state) => state.cart.total);
  const list = useSelector((state) => state.idqty.qty);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  // useTokenValid(auth);

  const handleClose = () => setShowEnquiryForm(false);
  const handleShow = () => setShowEnquiryForm(true);

  const updateCartHandler = () => {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const params = {
      qty: list,
      token: true,
    };
    if (token) {
      axios
        .put(`${BASE_URL}/checkout/cart/update`, params, config)
        .then((res) => {
          dispatch(populateCart(res?.data?.data?.items));
          setShowUpdMsg(true);
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      const keys = Object.keys(list);

      keys.forEach((key) => {
        const value = list[key];
        // console.log(key, value);
        dispatch(updateQuantity({ id: key, quantity: value }));
      });
      setShowUpdMsg(true);
    }
  };

  useEffect(() => {
    // console.log("called here");
    if (reduxCartItems) {
      const cartItems = [...reduxCartItems];
      setCartData(cartItems);
    }
  }, [reduxCartItems]);

  const handleCloseUndoMsg = () => {
    setShowUndoMsg(false);
  };

  const handleCloseUpdMsg = () => {
    setShowUpdMsg(false);
  };

  const undoDeletedCartProduct = () => {
    if (token) {
      handleCloseUndoMsg();
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const params = {
        product_id: undoProduct.id,
        quantity: undoProduct.qty,
        token: true,
      };
      axios
        .post(`${BASE_URL}/checkout/cart/add/${undoProduct.id}`, params, config)
        .then((res) => {
          // console.log(res?.data?.data?.items);
          dispatch(populateCart(res?.data?.data?.items));
          dispatch(clearList());
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      handleCloseUndoMsg();
      axios
        .get(`${BASE_URL}/products/${undoProduct.id}`)
        .then((res) => {
          // console.log(res?.data?.data);
          dispatch(
            addToCart({
              product: res?.data?.data,
              id: res?.data?.data.id,
              quantity: undoProduct.qty,
              name: res?.data?.data?.name,
            })
          );
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  // console.log(undoProduct);
  // console.log(token);
  // console.log(reduxCartItems);
  // console.log(list);

  return (
    <>
      <Helmet>
        <title>{`Cart - European Lorry Parts Industry`}</title>
        <meta property="og:title" content="Cart"></meta>
      </Helmet>
      {!cartData ? (
        <Loader />
      ) : (
        <div className="page-container container">
          {!showUndoMsg ? null : (
            <div className="my-5">
              <div className="msg-bar d-flex justify-content-between align-items-center py-2 px-3">
                <div className="d-flex flex-row">
                  <div
                    className="msg-bar-text"
                    style={{ color: '#808080', fontWeight: '500' }}
                  >{`"${undoProduct.name}" ${t('removed')}.`}</div>
                  <div
                    className="shortcut-btn ms-1"
                    onClick={undoDeletedCartProduct}
                    style={{ fontWeight: '500' }}
                  >
                    {` ${t('undo')}?`}
                  </div>
                </div>

                <div className="shortcut-btn">
                  <RxCross1 onClick={handleCloseUndoMsg} />
                </div>
              </div>
            </div>
          )}
          {!showUpdMsg ? null : (
            <div className="my-5">
              <div className="msg-bar d-flex justify-content-between align-items-center py-2 px-3">
                <div className="d-flex flex-row justify-content-center">
                  <div
                    className="msg-bar-text"
                    style={{ color: '#808080' }}
                  >{`${t('cart_updated')}`}</div>
                </div>
                <div className="shortcut-btn">
                  <RxCross1 onClick={handleCloseUpdMsg} />
                </div>
              </div>
            </div>
          )}
          {cartData.length === 0 ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '40vh' }}
            >
              <div
                className="msg-bar d-flex justify-content-between align-items-center py-3 px-3"
                style={{ width: '90%' }}
              >
                <div className="d-flex flex-row">
                  <div
                    className="cart-empty-msg flex-basis-1"
                    style={{ color: '#808080' }}
                  >{`${t('cart_empty')}.`}</div>
                </div>
                <div className="flex-basis-1">
                  <Link
                    to="/products"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="shortcut-btn d-flex flex-row">
                      <ImCart className="me-2 mt-1" />
                      <div>{`${t('return_to_shop')}`}</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="innerPage-container">
              <div className="cart-container my-5">
                <div className="cart-top d-flex justify-content-left ELPi-text-color my-4 mx-4">
                  <p className="h5">{`${t('cart_title1')} ${total} ${t(
                    'cart_title2'
                  )}`}</p>
                </div>
                <div className="container">
                  <div className="cart-title row ELPi-text-color mx-2 py-1">
                    <div className="col-xl-4 col-lg-4 col-md-4"></div>
                    <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
                      <p>
                        <b>{t('product')}</b>
                      </p>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
                      <p>
                        <b>{t('quantity')}</b>
                      </p>
                    </div>
                  </div>
                  <div>
                    {cartData.map((cartItem) => (
                      <CartCard
                        cartProduct={cartItem}
                        key={cartItem.id}
                        setShowUndoMsg={setShowUndoMsg}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-end">
                    <div
                      className="btn basic-btn my-5 mx-2"
                      onClick={updateCartHandler}
                    >
                      {t('update_cart')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn basic-btn my-2" onClick={handleShow}>
                  {t('send_enquiry')}
                </button>
              </div>
            </div>
          )}
          <Modal
            size="lg"
            show={showEnquiryForm}
            onHide={handleClose}
            className="px-0"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header
              style={{ backgroundColor: '#b58f6c', color: '#ffffff' }}
              closeButton
            >
              <Modal.Title
                className="text-center"
                style={{ fontWeight: '700' }}
              >
                {t('enquiry_form')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EnquiryForm
                show={showEnquiryForm}
                setShow={setShowEnquiryForm}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CartScreen;
