import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import "./ProductsSlider.css";
import { Link } from "react-router-dom";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";

const ProductsSlider = ({ allProductsAPIUrl }) => {
  const [sliderData, setSliderData] = useState();

  const getProductsSlides = async () => {
    const res = await axios.get(allProductsAPIUrl.url);
    const res2 = res?.data?.data.filter((slide) => {
      return slide.name !== "Root";
    });
    setSliderData(res2);
  };

  useEffect(() => {
    getProductsSlides();
  }, []);

  // console.log(sliderData);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Number(
      `${sliderData ? (sliderData.length > 6 ? 6 : sliderData.length) : 6}`
    ),
    slidesToScroll: 1,
    prevArrow: <HiChevronDoubleLeft />,
    nextArrow: <HiChevronDoubleRight />,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Number(
            `${
              sliderData ? (sliderData.length > 3 ? 3 : sliderData.length) : 3
            }`
          ),
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Number(
            `${
              sliderData ? (sliderData.length > 2 ? 2 : sliderData.length) : 2
            }`
          ),
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Number(
            `${
              sliderData ? (sliderData.length > 1 ? 1 : sliderData.length) : 1
            }`
          ),
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      {!sliderData ? null : (
        <div id="products-slider" className="container">
          <Slider {...settings} className="my-5">
            {sliderData.map((slide) => (
              <Link
                className="products-slider-link"
                key={slide.id}
                to={"/product-category/" + slide.slug + "/" + slide.id}
              >
                <div className="slider-container">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center align-item-center">
                      <img
                        className="slider-image py-3"
                        src={slide.image_url}
                        alt={slide.id}
                      />
                    </div>
                    <div className="text-center">
                      <p
                        style={{
                          color: "#b58f6c",
                          fontFamily: "'Roboto', sans-serif",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {slide.name}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default ProductsSlider;
