import React from "react";
import { useTranslation } from "react-i18next";

const QuickResultTileCategory = ({ quickResultItem, subCat }) => {
  const { t } = useTranslation();
  // console.log(quickResultItem);
  return (
    <div className="quick-result-tile-container d-flex flex-row rounded px-2 py-2">
      <div className="quick-result-tile-details d-flex flex-column justify-content-center">
        <div
          className="quick-result-tile-category"
          style={{ color: "#777777", fontSize: "10px", fontWeight: "500" }}
        >
          {t("CATEGORY")}
        </div>
        <div
          className="quick-result-tile-name"
          style={{ color: "#444444", fontSize: "15px", fontWeight: "700" }}
        >
          {quickResultItem?.name}
        </div>
        {subCat ? (
          <div
            className="quick-result-tile-details"
            style={{ color: "#444444", fontSize: "12px", fontWeight: "500" }}
          >
            {`${quickResultItem?.slug}`}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default QuickResultTileCategory;
