import React, { useState } from 'react';
import { useFormik } from 'formik';
import './EnquiryForm.css';
import { enquiryFormSchema } from '../../YupSchemas/FormValidation';
import { BASE_URL } from '../../Constants/Constants';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { emptyCart } from '../../ReduxStore/Store';

const EnquiryForm = ({ show, setShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const token = useSelector((state) => state.auth.token);
  const cart = useSelector((state) => state.cart.items);

  // console.log(token);
  // console.log(cart);
  const idArr = [];
  const qtArr = [];
  if (token) {
    cart.map((item) => {
      qtArr.push(item.quantity);
      idArr.push(item.product.id);
    });
  } else {
    cart.map((item) => {
      qtArr.push(item.quantity);
      idArr.push(item.id);
    });
  }

  // console.log(idArr);
  // console.log(qtArr);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: '',
        email: '',
        phone: '',
        address: '',
        subject: '',
        message: '',
      },
      validationSchema: enquiryFormSchema,
      onSubmit: (values, action) => {
        const params = new URLSearchParams();
        params.append('name', values.name);
        params.append('email', values.email);
        params.append('phone', values.phone);
        params.append('address', values.address);
        params.append('subject', values.subject);
        params.append('message_body', values.message);
        params.append('pid', idArr.join(','));
        params.append('quantity', qtArr.join(','));
        sendUserEnquiryDetails(params, action);
      },
    });

  const sendUserEnquiryDetails = (params, action) => {
    setIsLoading(true);
    axios
      .post(`${BASE_URL}/quotes`, params)
      .then((res) => {
        // console.log(res);
        setSuccess(res?.data?.message);
        action.resetForm();
        setShow(false);
        setIsLoading(false);
        dispatch(emptyCart());
        if (token) {
          emptyCarBackend();
        }
      })
      .catch((error) => {
        // console.log(error);
        setError(error?.message);
        setIsLoading(false);
      });
  };

  const emptyCarBackend = () => {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BASE_URL}/checkout/cart/empty?token=true`, config)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  let outputString = '';

  for (let i = 0; i < cart.length; i++) {
    outputString += `${cart[i].quantity} x ${cart[i].name}\n`;
  }

  // console.log(success);
  // console.log(error);
  // console.log(cart);
  // console.log(token);

  return (
    <div className="ELPi-text-color">
      <form onSubmit={handleSubmit}>
        <div className="form-group my-3">
          <label style={{ fontWeight: '500' }} className="mb-1" htmlFor="name">
            {t('your_name')}
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder={t('enter_your_name')}
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            autoFocus
          />
          {touched.name ? (
            <p className="form-error" style={{ color: 'red' }}>
              {errors.name}
            </p>
          ) : null}
        </div>
        <div className="form-group my-3">
          <label style={{ fontWeight: '500' }} className="mb-1" htmlFor="email">
            {t('your_email')}
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            id="email"
            placeholder={t('enter_your_email')}
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            autoFocus
          />
          {touched.email ? (
            <p className="form-error" style={{ color: 'red' }}>
              {errors.email}
            </p>
          ) : null}
        </div>
        <div className="form-group my-3">
          <label style={{ fontWeight: '500' }} className="mb-1" htmlFor="phone">
            {t('your_phone')}
          </label>
          <input
            type="tel"
            name="phone"
            className="form-control"
            id="phone"
            placeholder={t('enter_your_phone')}
            onChange={handleChange}
            value={values.phone}
            onBlur={handleBlur}
            autoFocus
          />
          {touched.phone ? (
            <p className="form-error" style={{ color: 'red' }}>
              {errors.phone}
            </p>
          ) : null}
        </div>
        <div className="form-group my-3">
          <label
            style={{ fontWeight: '500' }}
            className="mb-1"
            htmlFor="address"
          >
            {t('your_address')}
          </label>
          <input
            type="text"
            name="address"
            className="form-control"
            id="address"
            placeholder={t('enter_your_address')}
            onChange={handleChange}
            value={values.address}
            onBlur={handleBlur}
            autoFocus
          />
          {touched.address ? (
            <p className="form-error" style={{ color: 'red' }}>
              {errors.address}
            </p>
          ) : null}
        </div>
        <div className="form-group my-3">
          <label
            style={{ fontWeight: '500' }}
            className="mb-1"
            htmlFor="subject"
          >
            {t('subject')}
          </label>
          <input
            type="text"
            name="subject"
            className="form-control"
            id="subject"
            placeholder={t('enter_your_subject')}
            onChange={handleChange}
            value={values.subject}
            onBlur={handleBlur}
            autoFocus
          />
          {touched.subject ? (
            <p className="form-error" style={{ color: 'red' }}>
              {errors.subject}
            </p>
          ) : null}
        </div>
        <div className="form-group my-3">
          <label
            style={{ fontWeight: '500' }}
            className="mb-1"
            htmlFor="message"
          >
            {t('your_message')}
          </label>
          <textarea
            name="message"
            className="form-control"
            id="message"
            rows="3"
            onChange={handleChange}
            value={values.message}
            onBlur={handleBlur}
            autoFocus
          ></textarea>
        </div>
        <div className="form-group my-3">
          <label
            style={{ fontWeight: '500' }}
            className="mb-1"
            htmlFor="products"
          >
            {t('products')}
          </label>
          <textarea
            name="products"
            className="form-control products-output"
            id="products"
            rows="3"
            value={outputString}
            autoFocus
            readOnly
          ></textarea>
        </div>
        <div>
          <button type="submit" className="btn modal-btn basic-btn my-2">
            {t('SEND')}
          </button>
        </div>
      </form>
      {error ? (
        <p className="text-center mt-2" style={{ color: 'red' }}>
          {`${t('Something went wrong')}`}
        </p>
      ) : null}
    </div>
  );
};

export default EnquiryForm;
