import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./NavbarComponent.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constants/Constants";
import { ImCart } from "react-icons/im";
import { IoMenu } from "react-icons/io5";
import { useSelector } from "react-redux";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import { useTranslation } from "react-i18next";

function NavbarComponent() {
  const { t } = useTranslation();
  const navbarRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [navbarData, setNavbarData] = useState();
  const [menuItemLength, setMenuItemLength] = useState(-1);
  const [itemCount, setItemCount] = useState(undefined);
  const auth = useSelector((state) => state.auth);
  const total = useSelector((state) => state.cart.total);

  const lang = useSelector((state) => state.lang.ELPiLang);

  const getNavbarData = async (pathComponent) => {
    const res = await axios.get(`${BASE_URL}/${pathComponent}`);
    setNavbarData(res?.data);
    let Menu = res?.data?.Header_Menu;
    // console.log(Menu);
    setMenuItemLength(Menu?.length);
  };

  useEffect(() => {
    if (lang === "fr") {
      getNavbarData(`frech-header`);
    } else {
      getNavbarData(`header`);
    }
  }, [lang]);

  useEffect(() => {
    const handleClick = (e) => {
      if (navbarRef.current && !navbarRef.current.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  let link = document.querySelector(".faviconClass");
  if (navbarData) {
    link.href = navbarData?.Logo[0]?.favicon_url;
  }

  useEffect(() => {
    setItemCount(total);
  }, [total]);

  return (
    <>
      {!navbarData ? null : (
        <>
          <Navbar
            collapseOnSelect
            // expand="lg"
            bg="dark"
            variant="dark"
            className="custom-navbar navbar-pc"
            fixed="top"
          >
            <Container>
              <NavLink
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img
                  className="logo-img"
                  src={navbarData?.Logo[0]?.logo_url}
                  alt="logo"
                />
              </NavLink>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                style={{ color: "transparent" }}
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto">
                  {navbarData?.Header_Menu.map((menuItem, i) =>
                    //if last item
                    i + 1 === menuItemLength ? (
                      //if icon
                      menuItem.type === "icon" ? (
                        //if it is cart
                        menuItem.page_link === "cart" ? (
                          <NavLink
                            to={"/" + menuItem.page_link}
                            key={menuItem.id}
                            activeClassName="active"
                            className="nav-text-pc d-flex align-items-center justify-content-center"
                            style={{ textDecoration: "none" }}
                          >
                            <ImCart />
                            {itemCount === 0 ? null : (
                              <span
                                className="badge badge-warning"
                                id="lblCartCount"
                              >
                                {itemCount}
                              </span>
                            )}
                          </NavLink>
                        ) : (
                          <NavLink
                            to={"/" + menuItem.page_link}
                            key={menuItem.id}
                            activeClassName="active"
                            className="nav-text-pc d-flex align-items-center justify-content-center px-1 text-center"
                            style={{ textDecoration: "none" }}
                          >
                            <ImCart />
                          </NavLink>
                        )
                      ) : menuItem.title === "Login" ||
                        menuItem.title === "Connexion" ? //    &&
                      // auth.token
                      // <NavLink
                      //   to={"/my-account"}
                      //   key={menuItem.id}
                      //   activeClassName="active"
                      //   className="nav-text-pc d-flex align-items-center justify-content-center px-1 text-center"
                      //   style={{ textDecoration: "none" }}
                      // >
                      //   {`${t("Account")}`}
                      // </NavLink>
                      null : (
                        <NavLink
                          to={"/" + menuItem.page_link}
                          key={menuItem.id}
                          activeClassName="active"
                          className="nav-text-pc d-flex align-items-center justify-content-center px-1 text-center"
                          style={{ textDecoration: "none" }}
                        >
                          {menuItem.title}
                        </NavLink>
                      )
                    ) : // if not last item
                    // if icon
                    menuItem.type === "icon" ? (
                      //if it is cart
                      menuItem.page_link === "cart" ? (
                        <NavLink
                          to={"/" + menuItem.page_link}
                          key={menuItem.id}
                          activeClassName="active"
                          className="nav-text-pc me-3 d-flex align-items-center justify-content-center px-1 text-center"
                          style={{ textDecoration: "none" }}
                        >
                          <ImCart />
                          {itemCount === 0 ? null : (
                            <span
                              className="badge badge-warning"
                              id="lblCartCount"
                            >
                              {itemCount}
                            </span>
                          )}
                        </NavLink>
                      ) : (
                        <NavLink
                          to={"/" + menuItem.page_link}
                          key={menuItem.id}
                          activeClassName="active"
                          className="nav-text-pc me-3 d-flex align-items-center justify-content-center px-1 text-center"
                          style={{ textDecoration: "none" }}
                        >
                          <ImCart />
                        </NavLink>
                      )
                    ) : menuItem.title === "Login" ||
                      menuItem.title === "Connexion" ? // auth.token //   &&
                    // <NavLink
                    //   to={"/my-account"}
                    //   key={menuItem.id}
                    //   activeClassName="active"
                    //   className="nav-text-pc me-3 d-flex align-items-center justify-content-center px-1 text-center"
                    //   style={{ textDecoration: "none" }}
                    // >
                    //   {`${t("Account")}`}
                    // </NavLink>
                    null : (
                      <NavLink
                        to={"/" + menuItem.page_link}
                        key={menuItem.id}
                        activeClassName="active"
                        className="nav-text-pc me-3 d-flex align-items-center justify-content-center px-1 text-center"
                        style={{ textDecoration: "none" }}
                      >
                        {menuItem.title}
                      </NavLink>
                    )
                  )}
                </Nav>
              </Navbar.Collapse>
              <LanguageToggle />
            </Container>
          </Navbar>
          <Navbar
            ref={navbarRef}
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            className="custom-navbar navbar-mobile"
            fixed="top"
            expanded={expanded}
          >
            <Container>
              <NavLink
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => setExpanded(false)}
              >
                <img
                  className="logo-img"
                  src={navbarData?.Logo[0]?.logo_url}
                  alt="logo"
                />
              </NavLink>
              <div className="ms-auto">
                {navbarData?.Header_Menu.map((menuItem, i) =>
                  // if icon

                  menuItem.type === "icon" ? (
                    //  if it is cart
                    menuItem.page_link === "cart" ? (
                      <NavLink
                        to={"/" + menuItem.page_link}
                        key={menuItem.id}
                        className="nav-text-mobile px-2"
                        style={{ textDecoration: "none", fontSize: "20px" }}
                        onClick={() => setExpanded(false)}
                      >
                        <ImCart />
                        {itemCount === 0 ? null : (
                          <span
                            className="badge badge-warning"
                            id="lblCartCount"
                          >
                            {itemCount}
                          </span>
                        )}
                      </NavLink>
                    ) : (
                      <NavLink
                        to={"/" + menuItem.page_link}
                        key={menuItem.id}
                        className="nav-text-mobile px-2"
                        style={{ textDecoration: "none", fontSize: "20px" }}
                        onClick={() => setExpanded(false)}
                      >
                        <ImCart />
                      </NavLink>
                    )
                  ) : null
                )}
              </div>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                style={{ color: "#b58f6c" }}
                onClick={() => setExpanded(expanded ? false : "expanded")}
              >
                <IoMenu style={{ fontSize: "30px" }} />
              </Navbar.Toggle>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto">
                  {navbarData?.Header_Menu.map((menuItem, i) =>
                    menuItem.type === "icon" ? null : i === 0 ? ( // if not icon
                      menuItem.title === "Login" ||
                      menuItem.title === "Connexion" ? null : ( // // auth.token //   &&
                        <NavLink
                          to={"/" + menuItem.page_link}
                          key={menuItem.id}
                          activeClassName="active"
                          className="nav-text-mobile px-4 pt-2 pb-4"
                          style={{
                            textDecoration: "none",
                          }}
                          onClick={() => setExpanded(false)}
                        >
                          {menuItem.title}
                        </NavLink>
                      )
                    ) : menuItem.title === "Login" ||
                      menuItem.title === "Connexion" ? // // auth.token //    &&
                    null : (
                      <NavLink
                        to={"/" + menuItem.page_link}
                        key={menuItem.id}
                        activeClassName="active"
                        className="nav-text-mobile px-4 pt-2 pb-4"
                        style={{
                          textDecoration: "none",
                          borderTop: "1px solid #b58f6c",
                        }}
                        onClick={() => setExpanded(false)}
                      >
                        {menuItem.title}
                      </NavLink>
                    )
                  )}
                </Nav>
              </Navbar.Collapse>
              <LanguageToggle />
            </Container>
          </Navbar>
        </>
      )}
    </>
  );
}

export default NavbarComponent;
