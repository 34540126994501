import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../src/Assets/Locales/en/translation.json";
import translationFR from "../src/Assets/Locales/fr/translation.json";

const lang = localStorage.getItem("lang");

// Configure i18n
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
  },
  lng: lang,
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
