import React, { useState, useEffect } from "react";
// import { LatestProductsData } from "../../Config/LatestProductsData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./LatestProductsSlider.css";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const LatestProductsSlider = ({ latestProductsAPIUrl }) => {
  // console.log(latestProductsAPIUrl);
  const { t } = useTranslation();
  const [sliderData, setSliderData] = useState(undefined);

  const getProductsSlides = async () => {
    const res = await axios.get(latestProductsAPIUrl.url);
    setSliderData(res?.data);
  };

  useEffect(() => {
    getProductsSlides();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Number(
      `${sliderData ? (sliderData.length > 4 ? 4 : sliderData.length) : 4}`
    ),
    slidesToScroll: 1,
    prevArrow: <HiChevronDoubleLeft />,
    nextArrow: <HiChevronDoubleRight />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Number(
            `${
              sliderData ? (sliderData.length > 3 ? 3 : sliderData.length) : 3
            }`
          ),
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Number(
            `${
              sliderData ? (sliderData.length > 2 ? 2 : sliderData.length) : 2
            }`
          ),
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Number(
            `${
              sliderData ? (sliderData.length > 1 ? 1 : sliderData.length) : 1
            }`
          ),
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  // console.log(sliderData)

  return (
    <>
      {!sliderData ? null : (
        <div className="latest-section my-5 d-flex flex-column justify-content-center">
          <div className="container">
            <p className="h1 text-center" style={{ color: "#b58f6c" }}>
              {t("latest_products")}
            </p>
            {/* mt-3 mx-3 */}
            {/* my-5 */}
            <Slider {...settings} className="my-5">
              {sliderData.map((slide) => (
                <Link
                  className="latest-slider-link"
                  to={`/product/specific/${slide?.url_key}`}
                  key={slide.id}
                >
                  {/* <div key={slide?.id} className="latest-slider-container">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          className="latest-slider-image"
                          src={slide?.images[0]?.url}
                          alt={slide?.id}
                        />
                      </div>
                      <div className="text-center pt-4">
                        <p
                          style={{
                            color: "#b58f6c",
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {slide?.name}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="slider-container">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center align-item-center">
                        <img
                          className="slider-image py-3 New__Slider_Img"
                          src={slide?.images[0]?.url}
                          alt={slide?.id}
                        />
                      </div>
                      <div className="text-center">
                        <p
                          style={{
                            color: "#b58f6c",
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {slide.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

export default LatestProductsSlider;
