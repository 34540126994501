import React, { useEffect, useState } from "react";
import GridViewComponent from "../../Components/GridViewComponent/GridViewComponent";
// import { CompanyProductsData } from "../../Config/CompanyProductsData";
import { BASE_URL } from "../../Constants/Constants";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { setFilteredProducts } from "../../ReduxStore/Store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

const SearchProductsScreen = () => {
  const { query } = useParams();
  const { pageNo } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterProducts, setFilterProducts] = useState(undefined);
  const totalFilteredProducts = useSelector(
    (state) => state.filtered.totalFProducts
  );

  const getNthPageProducts = () => {
    let configAll = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/allsearch?text=${query}&page=${pageNo}`,
      headers: {},
    };

    axios
      .request(configAll)
      .then((response) => {
        // console.log(response);
        setFilterProducts(response?.data?.data);
        dispatch(
          setFilteredProducts({
            total: totalFilteredProducts,
          })
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getNthPageProducts();
  }, [pageNo]);

  // useEffect(() => {
  //   if (totalFilteredProducts === 0) {
  //     navigate("/");
  //   }
  // }, [totalFilteredProducts]);

  // console.log(storeFilteredProducts);
  // console.log(filterProducts);
  //   console.log(allProducts);
  // console.log(totalFilteredProducts);

  const PER_PAGE = 9;
  const pageCount = Math.ceil(totalFilteredProducts / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    // console.log('selectedPage', selectedPage + 1);
    navigate(`/search/products/${query}/${selectedPage + 1}`);
  }

  return (
    <>
      <Helmet>
        <title>{`Search - European Lorry Parts Industry`}</title>
        <meta
          name="description"
          content="ELPI replacement parts for European Trucks and Buses like: DAF, IVECO,MACK, MAN, MERCEDES BENZ, RENAULT, SCANIA, VOLVO and for Agricultural Machineries like : CUMMINS, DUETZ , PERKINS"
        ></meta>
      </Helmet>
      <div className="page-container container">
        {filterProducts ? (
          filterProducts.length === 0 || totalFilteredProducts === 0 ? (
            <div className="py-4 d-flex justify-content-center">
              <div className="ELPi-text-color">{t(`No Products Found`)}</div>
            </div>
          ) : (
            <div className="row">
              {filterProducts.map((item) =>
                !item?.product ? (
                  item.hasOwnProperty("root") ? (
                    !item.root ? (
                      <div
                        key={item.id}
                        className="col-lg-4 col-md-6 col-sm-6 col-12"
                      >
                        <Link
                          to={`/category/specific/${item.slug}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <GridViewComponent
                            item={item}
                            isSearchedSubCat={true}
                          />
                        </Link>
                      </div>
                    ) : (
                      <div
                        key={item.id}
                        className="col-lg-4 col-md-6 col-sm-6 col-12"
                      >
                        <Link
                          to={"/product-category/" + item.slug + "/" + item.id}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <GridViewComponent item={item} />
                        </Link>
                      </div>
                    )
                  ) : null
                ) : (
                  <div
                    key={item.id}
                    className="col-lg-4 col-md-6 col-sm-6 col-12"
                  >
                    <Link
                      to={`/product/specific/${item.url_key}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <GridViewComponent item={item} />
                    </Link>
                  </div>
                )
              )}
            </div>
          )
        ) : (
          <Loader />
        )}
        {totalFilteredProducts > 9 ? (
          <ReactPaginate
            previousLabel={pageCount === 1 ? "Disabled" : "Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages_pagination"}
            activeClassName={"active"}
            disabledClassName={"pagination_link--disabled"}
          />
        ) : null}
      </div>
    </>
  );
};

export default SearchProductsScreen;
