import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loading-container d-flex justify-content-center align-items-center">
      <div className="spinner-grow" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

export default Loader;
