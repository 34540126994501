import React, { useState, useEffect } from 'react';
import CoverComponent from '../../Components/CoverComponent/CoverComponent';
import Searchbar from '../../Components/Searchbar/Searchbar';
import MissionStatement from '../../Components/MissionStatement/MissionStatement';
import TabSection from '../../Components/TabSection/TabSection';
import LatestProductsSlider from '../../Components/LatestProductsSlider/LatestProductsSlider';
import CustomerSlider from '../../Components/CustomerSlider/CustomerSlider';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import Loader from '../../Components/Loader/Loader';
import { checkToken } from '../../Components/Custom Hooks/checkToken';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const HomeScreen = () => {
  const [order, setOrder] = useState();
  const [coverAPIUrl, setCoverAPIUrl] = useState();
  const [aboutUsData, setAboutUsData] = useState();
  const [allProductsAPIUrl, setAllProductsAPIUrl] = useState();
  const [latestProductsAPIUrl, setLatestProductsAPIUrl] = useState();
  const [customerSliderData, setCustomerSliderData] = useState();
  const [mapImage, setMapImage] = useState();
  const [homepageData, setHomepageData] = useState();

  const lang = useSelector((state) => state.lang.ELPiLang);

  const getHomepageData = async (pathComponent) => {
    try {
      let res = await axios.get(`${BASE_URL}/${pathComponent}`);
      // console.log(res);
      setHomepageData(res?.data);
      setCoverAPIUrl(res?.data?.Cover);
      setAboutUsData(res?.data?.About_Us);
      setAllProductsAPIUrl(res?.data?.Category);
      setLatestProductsAPIUrl(res?.data?.Latest_Products);
      setCustomerSliderData(res?.data?.testimonial);
      setMapImage(res?.data?.Map_img);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (lang === 'fr') {
      getHomepageData(`Homepage-french-content`);
    } else {
      getHomepageData(`Homepagecontent`);
    }
  }, [lang]);

  // console.log(latestProductsAPIUrl)

  return (
    <>
      <Helmet>
        <title>{`ELPI: European Lorry Parts Industry`}</title>
        <meta
          name="description"
          content="ELPI replacement parts for European Trucks and Buses like: DAF, IVECO,MACK, MAN, MERCEDES BENZ, RENAULT, SCANIA, VOLVO and for Agricultural Machineries like : CUMMINS, DUETZ , PERKINS"
        ></meta>
      </Helmet>
      <div className="page-container ">
        {!homepageData ? (
          <Loader />
        ) : (
          <>
            {!coverAPIUrl ? null : <CoverComponent coverAPIUrl={coverAPIUrl} />}
            <div className="container-fluid">
              <Searchbar />
              {!aboutUsData ? null : (
                <MissionStatement aboutUsData={aboutUsData} />
              )}

              {!allProductsAPIUrl ? null : (
                <TabSection
                  allProductsAPIUrl={allProductsAPIUrl}
                  mapImage={mapImage}
                />
              )}
            </div>
            <div>
              {!latestProductsAPIUrl ? null : (
                <LatestProductsSlider
                  latestProductsAPIUrl={latestProductsAPIUrl}
                />
              )}
            </div>
            <div className="container-fluid">
              <CustomerSlider customerSliderData={customerSliderData} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HomeScreen;
