import { useRouteError } from "react-router-dom";
import { Helmet } from "react-helmet";

const Error = () => {
  const err = useRouteError();
  return (
    <>
      <Helmet>
        <title>{`Error - ${err.statusText}`}</title>
        <meta
          name="description"
          content="ELPI replacement parts for European Trucks and Buses like: DAF, IVECO,MACK, MAN, MERCEDES BENZ, RENAULT, SCANIA, VOLVO and for Agricultural Machineries like : CUMMINS, DUETZ , PERKINS"
        ></meta>
      </Helmet>
      <Helmet>
        <title>ELPI: European Lorry Parts Industry</title>
        <meta
          name="description"
          content="ELPI replacement parts for European Trucks and Buses like: DAF, IVECO,MACK, MAN, MERCEDES BENZ, RENAULT, SCANIA, VOLVO and for Agricultural Machineries like : CUMMINS, DUETZ , PERKINS"
        ></meta>
      </Helmet>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", width: "100vw" }}
      >
        <div className="d-flex flex-row ELPi-text-color">
          <div className="me-3">Oops!</div>
          <div className="">{`${err.status} : ${err.statusText}`}</div>
        </div>
      </div>
    </>
  );
};
export default Error;
