import React from "react";
import "./CountryMap.css";
// const map = require("../../Assets/Images/TabImage/countryMap.jpg");

export const CountryMap = ({ mapImage }) => {
  // console.log(mapImage);
  return (
    <div className="container">
      <div className="map-img-container my-5">
        <img className="map-img" src={mapImage[0].image_url} alt="map" />
      </div>
    </div>
  );
};

export default CountryMap;
