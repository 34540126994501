import React from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import { MdOutlineFax } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { BsWhatsapp } from 'react-icons/bs';
import './ContactUsDetails.css';

const ContactUsDetails = ({ contactUsData }) => {
  // console.log(contactUsData);
  const [company_logo, ...rem_name] = contactUsData.company_name.split(' ');

  return (
    <div className="d-flex flex-column ELPi-text-color">
      <div className=" my-2 d-flex flex-column">
        <div className="d-flex flex-row align-items-end">
          <p className="h2 me-1" style={{ fontWeight: '600' }}>
            {company_logo}
          </p>
          <p className="h6 py-1">{rem_name.join(' ')}</p>
        </div>
        <div>
          <p className="h6">{contactUsData?.address}</p>
        </div>
      </div>
      <div className="d-flex my-1">
        <BiPhoneCall
          style={{ color: '#ffffff', fontSize: '27px', marginRight: '30px' }}
        />
        <p className="contact-us-text" style={{ fontWeight: '500' }}>
          {contactUsData?.phone_number}
        </p>
      </div>
      <div className="d-flex my-1">
        <MdOutlineFax
          style={{ color: '#ffffff', fontSize: '27px', marginRight: '30px' }}
        />
        <p className="contact-us-text" style={{ fontWeight: '500' }}>
          {contactUsData?.fax_number}
        </p>
      </div>
      <div className="d-flex my-1">
        <AiOutlineMail
          style={{ color: '#ffffff', fontSize: '27px', marginRight: '30px' }}
        />
        <p className="contact-us-text" style={{ fontWeight: '500' }}>
          {contactUsData?.email}
        </p>
      </div>
      <div className="d-flex my-1">
        <BsWhatsapp
          style={{ color: '#4b9b29', fontSize: '27px', marginRight: '30px' }}
        />
        <p className="contact-us-text" style={{ fontWeight: '500' }}>
          {contactUsData?.whatsapp_number}
        </p>
      </div>
    </div>
  );
};

export default ContactUsDetails;
