import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
  },
  reducers: {
    setToken(state, action) {
      const { user, token } = action.payload;
      localStorage.setItem('token', token);
      state.token = token;
      localStorage.setItem('user', JSON.stringify(user));
      state.user = user;
    },
    clearToken(state) {
      localStorage.removeItem('token');
      state.token = undefined;
      localStorage.removeItem('user');
      state.user = undefined;
    },
  },
});

const undoDelSlice = createSlice({
  name: 'undo',
  initialState: {
    id: JSON.parse(localStorage.getItem('undoId')) || undefined,
    name: JSON.parse(localStorage.getItem('undoName')) || undefined,
    qty: JSON.parse(localStorage.getItem('undoQty')) || undefined,
  },
  reducers: {
    setUndo(state, action) {
      const { id, name, qty } = action.payload;
      state.id = id;
      state.name = name;
      state.qty = qty;
      localStorage.setItem('undoId', JSON.stringify(state.id));
      localStorage.setItem('undoName', JSON.stringify(state.name));
      localStorage.setItem('undoQty', JSON.stringify(state.qty));
    },
    clearUndo(state) {
      state.id = undefined;
      state.name = undefined;
      state.qty = undefined;
      localStorage.removeItem('undoId');
      localStorage.removeItem('undoName');
      localStorage.removeItem('undoQty');
    },
  },
});

const incDecSlice = createSlice({
  name: 'idqty',
  initialState: {
    qty: {},
  },
  reducers: {
    updateList: (state, action) => {
      const { cart_item_id, quantity } = action.payload;
      state.qty[`${cart_item_id}`] = Number(quantity);
    },
    removeFromList: (state, action) => {
      const { cart_item_id } = action.payload;
      if (state.qty[`${cart_item_id}`] || state.qty[`${cart_item_id}`] === 0) {
        delete state.qty.cart_item_id;
      }
    },
    clearList: (state) => {
      state.qty = {};
    },
  },
});

const showMsgSlice = createSlice({
  name: 'expiredMsg',
  initialState: {
    msg: false,
  },
  reducers: {
    setShowMsgTrue: (state, action) => {
      state.msg = true;
    },
    setShowMsgFalse: (state, action) => {
      state.msg = false;
    },
  },
});

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: JSON.parse(localStorage.getItem('profile')) || [],
  },
  reducers: {
    setProfile(state, action) {
      const { user } = action.payload;
      state.user = user;
      localStorage.setItem('profile', JSON.stringify(state.user));
    },
    clearProfile(state) {
      state.user = [];
      localStorage.removeItem('profile');
    },
  },
});

const addressSlice = createSlice({
  name: 'address',
  initialState: {
    shippingAddr: JSON.parse(localStorage.getItem('shippingAddr')) || [],
    billingAddr: JSON.parse(localStorage.getItem('billingAddr')) || [],
  },
  reducers: {
    setAddress: (state, action) => {
      const { shippingAddr, billingAddr } = action.payload;
      state.shippingAddr =
        shippingAddr || JSON.parse(localStorage.getItem('shippingAddr')) || [];
      state.billingAddr =
        billingAddr || JSON.parse(localStorage.getItem('billingAddr')) || [];
      localStorage.setItem('shippingAddr', JSON.stringify(state.shippingAddr));
      localStorage.setItem('billingAddr', JSON.stringify(state.billingAddr));
    },
    clearShippingAddress: (state) => {
      state.shippingAddr = [];
      localStorage.removeItem('shippingAddr');
    },
    clearBillingAddress: (state) => {
      state.billingAddr = [];
      localStorage.removeItem('billingAddr');
    },
  },
});

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: JSON.parse(localStorage.getItem('cart')) || [],
    total: JSON.parse(localStorage.getItem('total')) || 0,
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
      } else {
        state.items.push(newItem);
      }
      // state.items.push(action.payload); // Otherwise add the new item to the cart
      state.total += newItem.quantity;
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    removeFromCart: (state, action) => {
      // const targetItem = state.items.filter(
      //   (item) => item.id === action.payload.id
      // );
      state.items = state.items.filter((item) => item.id !== action.payload.id);
      // if (targetItem) {
      state.total = state.total - action.payload.quantity;
      // console.log(state.total);
      // }
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;

      for (var i = 0; i < state.items.length; i++) {
        if (state.items[i].id == id) {
          state.items[i].quantity = quantity;
          let sum = 0;
          for (let i = 0; i < state.items.length; i++) {
            sum = sum + state.items[i].quantity;
          }
          state.total = sum;
          localStorage.setItem('cart', JSON.stringify(state.items));
          localStorage.setItem('total', JSON.stringify(state.total));
        }
      }
    },
    emptyCart: (state) => {
      state.items = [];
      state.total = 0;
      localStorage.setItem('total', 0);
      localStorage.setItem('cart', '[]');
    },
    populateCart: (state, action) => {
      state.items = action.payload;
      if (state.items) {
        const total = state.items.reduce((accumulator, item) => {
          return accumulator + item.quantity;
        }, 0);
        state.total = total;
      }
      localStorage.setItem('total', JSON.stringify(state.total));
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
  },
});

const langSlice = createSlice({
  name: 'lang',
  initialState: {
    ELPiLang: localStorage.getItem('lang') || 'en',
  },
  reducers: {
    setLangToFrench(state) {
      state.ELPiLang = 'fr';
      localStorage.setItem('lang', state.ELPiLang);
    },
    setLangToEnglish(state) {
      state.ELPiLang = 'en';
      localStorage.setItem('lang', state.ELPiLang);
    },
  },
});

const filteredSlice = createSlice({
  name: 'filtered',
  initialState: {
    totalFProducts: JSON.parse(localStorage.getItem('tFilteredP')) || 0,
  },
  reducers: {
    setFilteredProducts(state, action) {
      const { total } = action.payload;
      state.totalFProducts = total;
      localStorage.setItem('tFilteredP', JSON.stringify(state.totalFProducts));
    },
    clearFilteredProducts(state) {
      state.totalFProducts = 0;
      localStorage.removeItem('tFilteredP');
    },
  },
});

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    undo: undoDelSlice.reducer,
    cart: cartSlice.reducer,
    idqty: incDecSlice.reducer,
    address: addressSlice.reducer,
    profile: profileSlice.reducer,
    lang: langSlice.reducer,
    filtered: filteredSlice.reducer,
    expiredMsg: showMsgSlice.reducer,
  },
});

export const { setToken, clearToken } = authSlice.actions;
export const { setUndo, clearUndo } = undoDelSlice.actions;
export const {
  addToCart,
  removeFromCart,
  updateQuantity,
  emptyCart,
  populateCart,
} = cartSlice.actions;
export const { updateList, removeFromList, clearList } = incDecSlice.actions;
export const { setAddress, clearBillingAddress, clearShippingAddress } =
  addressSlice.actions;
export const { setProfile, clearProfile } = profileSlice.actions;
export const { setLangToFrench, setLangToEnglish } = langSlice.actions;
export const { setFilteredProducts, clearFilteredProducts } =
  filteredSlice.actions;
export const { setShowMsgTrue, setShowMsgFalse } = showMsgSlice.actions;

export default store;
