import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { BsFacebook } from "react-icons/bs";
// import { BsInstagram } from "react-icons/bs";
// import { BsTwitter } from "react-icons/bs";
import { MDBFooter } from "mdb-react-ui-kit";
// import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const [footerText, setFooterText] = useState("");

  const url = useLocation().pathname;

  useEffect(() => {
    if (url.includes("product") || url.includes("product-category")) {
      setFooterText(t("footer2"));
    } else {
      setFooterText("");
    }
  }, [url]);

  return (
    <MDBFooter className="text-center text-lg-start mt-5">
      {/* <MDBContainer className="text-md-start mt-5 px-5" fluid>
          <MDBRow>
            <div className="view-in-mobile pt-5">
              <MDBCol sm="12" className="col-12 mx-auto">
                <div className="d-flex justify-content-center mb-3">
                  <img className="logo-img" src={logo} alt="logo" />
                </div>
                <p className="text-center">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lore
                </p>
              </MDBCol>
            </div>
            <MDBCol
              sm="6"
              md="2"
              lg="2"
              xl="2"
              className="col-6 d-flex flex-column align-items-start mx-auto py-3"
            >
              <h5 className="fw-bold mb-3">About Us</h5>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Login
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  My account
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Cart
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Checkout
                </a>
              </p>
            </MDBCol>

            <MDBCol
              sm="6"
              md="2"
              lg="2"
              xl="2"
              className="col-6 d-flex flex-column align-items-start mx-auto py-3"
            >
              <h5 className="fw-bold mb-3">Help</h5>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Payment
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Shipping
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Cancellation
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  FAQ
                </a>
              </p>
            </MDBCol>

            <MDBCol
              sm="6"
              md="2"
              lg="2"
              xl="2"
              className="col-6 d-flex flex-column align-items-start mx-auto py-3"
            >
              <h5 className="fw-bold mb-3">Information</h5>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Privacy Policy
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Your account
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Settings
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Information
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Help
                </a>
              </p>
            </MDBCol>

            <MDBCol
              sm="6"
              md="2"
              lg="2"
              xl="2"
              className="col-6 d-flex flex-column align-items-start mx-auto py-3"
            >
              <h5 className="fw-bold mb-3">Contact us</h5>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Shipping Options
                </a>
              </p>
              <p className="footer-links" style={{ textAlign: "left" }}>
                <a href="#!" className="text-reset">
                  Call: 12345 6789
                </a>
              </p>
            </MDBCol>

            <MDBCol sm="12" md="12" lg="2" xl="2" className="col-12 mx-auto">
              <div className="view-in-desktoptablet">
                <div className="footer-links">
                  <img className="logo-img-footer" src={logo} alt="logo" />
                </div>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lore
                </p>
              </div>
              <hr style={{ height: "3px" }} />
              <div className="d-flex socialIcons">
                <a
                  href="#!"
                  className="d-flex justify-content-center align-items-center"
                >
                  <BsFacebook />
                </a>
                <a
                  href="#!"
                  className="d-flex justify-content-center align-items-center"
                >
                  <BsInstagram />
                </a>
                <a
                  href="#!"
                  className="d-flex justify-content-center align-items-center"
                >
                  <BsTwitter />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer> */}
      <div
        className="d-flex flex-column p-2"
        style={{
          color: "#a3a3a3",
          fontSize: "12px",
        }}
      >
        <div className="text-center">{t("footer1")}</div>
      </div>
      {footerText ? (
        <div
          className="d-flex flex-column p-2"
          style={{
            color: "#a3a3a3",
            fontSize: "12px",
          }}
        >
          <div className="text-center">{footerText}</div>
        </div>
      ) : null}
    </MDBFooter>
  );
};

export default Footer;
