import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./TabSection.css";
import ProductsSlider from "../ProductsSlider/ProductsSlider";
import CountryMap from "../CountryMap/CountryMap";
import { useTranslation } from "react-i18next";

function TabSection({ allProductsAPIUrl, mapImage }) {
  const { t } = useTranslation();
  return (
    <div className="container my-5">
      <Tabs>
        <TabList>
          <div className="d-flex justify-content-around">
            <Tab style={{ fontWeight: 900 }}>{t("tabTitle1")}</Tab>
            <Tab style={{ fontWeight: 900 }}>{t("tabTitle2")}</Tab>
          </div>
        </TabList>
        <TabPanel>
          <ProductsSlider allProductsAPIUrl={allProductsAPIUrl} />
        </TabPanel>
        <TabPanel>
          <CountryMap mapImage={mapImage} />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default TabSection;
