import React, { useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../Constants/Constants';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeFromCart, updateList } from '../../ReduxStore/Store';
import { setUndo } from '../../ReduxStore/Store';
import { removeFromList } from '../../ReduxStore/Store';
import './CartCard.css';

const CartCard = ({ cartProduct, setShowUndoMsg }) => {
  // console.log(cartProduct);
  const [qty, setQty] = useState(cartProduct?.quantity);
  const [product, setProduct] = useState(cartProduct?.product);
  const [productName, setProductName] = useState(cartProduct?.name);
  const token = useSelector((state) => state.auth.token);
  const cart = useSelector((state) => state.cart);
  const undo = useSelector((state) => state.undo);
  const list = useSelector((state) => state.idqty);

  const dispatch = useDispatch();

  const decQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
    if (qty === 1) {
      deleteCartProduct();
    }
  };

  const incQty = () => {
    setQty(Number(qty) + 1);
  };

  const handleChange = (e) => {
    if (e.target.value > -1) {
      setQty(e.target.value);
    }
  };

  const handleOnBlur = (e) => {
    const currentValue = Number(e.target.value);
    if (!currentValue) {
      // console.log('hii');
      setQty(1);
    }
  };

  useEffect(() => {
    dispatch(updateList({ cart_item_id: cartProduct?.id, quantity: qty }));
  }, [qty]);

  const deleteCartProduct = () => {
    dispatch(
      setUndo({
        id: cartProduct?.product?.id,
        name: cartProduct?.product?.name,
        qty: cartProduct?.quantity,
      })
    );
    if (token) {
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `${BASE_URL}/checkout/cart/remove-item/${cartProduct?.id}?token=true`,
          config
        )
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    dispatch(removeFromCart(cartProduct));
    dispatch(removeFromList(cartProduct?.id));
    setShowUndoMsg(true);
  };

  return (
    <div className="cart-product-card row mx-2 py-2 ELPi-text-color">
      <div className="cartProduct-cross" style={{ cursor: 'pointer' }}>
        <AiFillCloseCircle
          onClick={deleteCartProduct}
          style={{ fontSize: '25px', color: 'white' }}
        />
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center">
        <Link
          to={`/product/specific/${product.url_key}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <img
            className="cart-img my-2"
            src={product.images[0]?.original_image_url}
            alt={product?.name}
          />
        </Link>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center">
        <Link
          to={`/product/specific/${product.url_key}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {productName}
        </Link>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-center align-items-center">
        <div className="quantity-field my-2">
          <button className="val-btn dec-btn" onClick={decQty}>
            -
          </button>
          <input
            type="number"
            className="qty-num"
            value={qty}
            onChange={handleChange}
            onBlur={handleOnBlur}
            itemid={cartProduct?.id}
          />

          <button className="val-btn inc-btn" onClick={incQty}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
