import React from 'react';

const SmallLoader = () => {
  return (
    <div className="spinner-border" role="status">
      <span className="sr-only"></span>
    </div>
  );
};

export default SmallLoader;
