import React from "react";
import "./GridViewComponent.css";

const GridViewComponent = ({ item, isSearchedSubCat = false }) => {
  // console.log(item);
  return (
    <>
      <div
        className="product-grid-card py-3 px-3 my-3"
        style={{ backgroundColor: "black" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div
            className={`${
              item?.hasOwnProperty("root")
                ? item?.root
                  ? "img-container1 d-flex align-items-center justify-content-center"
                  : ""
                : "img-container2 d-flex align-items-center justify-content-center"
            }`}
          >
            {item.hasOwnProperty("root") ? (
              item?.root ? (
                <img
                  className="grid-img"
                  src={item?.image_url}
                  alt={item?.name}
                />
              ) : null
            ) : item?.images[0]?.url ? (
              <img
                className="grid-img"
                src={item?.images[0]?.url}
                alt={item?.name}
              />
            ) : null}
          </div>
          <div className=" product-grid-title d-flex justify-content-center align-items-center mt-3">
            <p
              className="ELPi-text-color text-center"
              style={{ fontWeight: "500" }}
            >
              {item?.name}
            </p>
          </div>
          {isSearchedSubCat ? (
            <div className="w-100 d-flex justify-content-end">
              <p
                className="ELPi-text-color me-3"
                style={{ fontSize: "12px", fontWeight: "400" }}
              >
                {`${item?.slug}`}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {/* <h1>hi</h1> */}
    </>
  );
};

export default GridViewComponent;
