import React, { useEffect, useState } from 'react';
import GridViewComponent from '../../Components/GridViewComponent/GridViewComponent';
import './AllCategoriesScreen.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import Loader from '../../Components/Loader/Loader';
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent';
import Dropdown from 'react-bootstrap/Dropdown';
import { HiChevronRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const AllCategoriesScreen = () => {
  const { t } = useTranslation();
  const [allCategories, setAllCategories] = useState();

  const lang = useSelector((state) => state.lang.ELPiLang);

  const getCategoriesSlides = async (pathComponent) => {
    const res = await axios.get(`${BASE_URL}/${pathComponent}`);
    const res2 = res?.data?.data.filter((slide) => {
      return slide.name !== 'Root';
    });

    // console.log(res);
    setAllCategories(res2);
  };

  useEffect(() => {
    if (lang === 'fr') {
      getCategoriesSlides(
        `descendant-categories?parent_id=70&limit=100&locale=fr`
      );
    } else {
      getCategoriesSlides(`descendant-categories?parent_id=70`);
    }
  }, [lang]);

  if (allCategories === undefined) {
    return <Loader />;
  }

  if (allCategories.length === 0) {
    return <EmptyComponent text={t('No Products Found')} />;
  }

  return (
    <>
      <Helmet>
        <title>{`Products - European Lorry Parts Industry`}</title>
        <meta
          property="og:title"
          content="Products - European Lorry Parts Industry"
        ></meta>
      </Helmet>
      {allCategories === undefined ? (
        <Loader />
      ) : (
        <div className="page-container container">
          <div className="row mt-5">
            <div className="col-lg-3 col-md-4 col-sm-12 col-12 my-3">
              <div className="view-in-largeScreen">
                <div className="d-flex justify-content-start mb-2">
                  <div
                    className="ELPi-text-color h5 ms-1"
                    style={{ fontWeight: '700' }}
                  >
                    {t('all_categories')}
                  </div>
                </div>
                {allCategories.map((category) => (
                  <Link
                    key={category.id}
                    to={
                      '/product-category/' + category.slug + '/' + category.id
                    }
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="list-text d-flex flex-row">
                      <div className="py-2 my-0" style={{ fontWeight: '500' }}>
                        <HiChevronRight className="me-1" />{' '}
                      </div>
                      <div
                        className="py-2 px-1 my-0"
                        style={{ fontWeight: '500', width: '100%' }}
                      >
                        {category.name}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="view-in-smallScreen">
                <Dropdown className="d-inline">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true ELPi-text-color h5"
                    variant="secondary"
                  >
                    Products Categories
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {allCategories.map((category) => (
                      <Dropdown.Item key={category.id}>
                        <Link
                          to={
                            '/product-category/' +
                            category.slug +
                            '/' +
                            category.id
                          }
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {category.name}
                        </Link>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12 col-12">
              <div className="row">
                {allCategories.map((category) => (
                  <div
                    key={category.id}
                    className="col-lg-4 col-md-6 col-sm-6 col-6"
                  >
                    <Link
                      to={
                        '/product-category/' + category.slug + '/' + category.id
                      }
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <GridViewComponent item={category} />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllCategoriesScreen;
