import React, { useState, useEffect } from 'react';
// import { AboutUsData } from "../../Config/AboutUsData";
import './AboutUsScreen.css';
import Loader from '../../Components/Loader/Loader';
import { BASE_URL } from '../../Constants/Constants';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const AboutUsScreen = () => {
  const [aboutUsData, setAboutUsData] = useState(undefined);
  const [aboutUsHeading, setAboutUsHeading] = useState(undefined);
  const lang = useSelector((state) => state.lang.ELPiLang);

  const getAboutUsData = (pathComponent) => {
    axios
      .get(`${BASE_URL}/${pathComponent}`)
      .then((res) => {
        setAboutUsHeading(res.data.About_Us_Data[0].heading);
        setAboutUsData(res.data.About_Us_Data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (lang === 'fr') {
      getAboutUsData('about-us-fr');
    } else {
      getAboutUsData('about-us');
    }
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>{`About us - European Lorry Parts Industry`}</title>
        <meta
          property="og:description"
          content="About us 

ELPI(European Lorry Parts Industry) supplies a selective range of quality products to the aftermarket which is constantly being expanded.

Having face to face discussion with our customers enables us to develop and expand the range of ELPI products according to their special requirements.

With advanced warehouse management system and high level of Inventory"
        ></meta>
      </Helmet>
      {!aboutUsData ? (
        <Loader />
      ) : (
        <div className="page-container">
          <div className="about-us-container">
            <div className="d-flex justify-content-center">
              <p className="h1 about-us-heading ELPi-text-color">
                {aboutUsHeading}
              </p>
            </div>
            <div className="container my-3">
              <div
                className="d-flex flex-column justify-content-center"
                style={{ fontWeight: '500' }}
              >
                {aboutUsData.map((aboutUsPara) => (
                  <p
                    key={aboutUsPara.id}
                    className="about-us-text h6 text-justify ELPi-text-color my-2"
                  >
                    {aboutUsPara.content}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutUsScreen;
