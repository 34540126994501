import React, { useState, useEffect, useRef } from "react";
import { BsSearch } from "react-icons/bs";
import "./Searchbar.css";
import { BASE_URL } from "../../Constants/Constants";
import axios from "axios";
import QuickResultTile from "./QuickResultTile";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilteredProducts,
  setFilteredProducts,
} from "../../ReduxStore/Store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuickResultTileCategory from "./QuickResultTileCategory";

const Searchbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tempCurrentPage, setTempCurrentPage] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState(undefined);
  const totalFiltered = useSelector((state) => state.filtered.totalFProducts);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      moveToSearchScreen();
    }
  };

  const handleFocus = () => {
    dispatch(clearFilteredProducts());
    if (searchTerm === "") {
      setSearch(undefined);
    }
    if (searchTerm.length > 2) {
      // console.log("called");
      const debounceTimer = setTimeout(() => {
        getResultsByAPI();
      }, 500);

      return () => {
        clearTimeout(debounceTimer);
      };
    }
  };

  const moveToSearchScreen = () => {
    if (searchTerm !== "") {
      if (search && tempCurrentPage) {
        if (search.length > 0) {
          // console.log("called");
          navigate(`/search/products/${searchTerm}/${tempCurrentPage}`);
        }
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearch(undefined);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const handleChange2 = (event) => {
    // console.log(event.target.value);
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm === "") {
      setSearch(undefined);
    }
    if (searchTerm.length > 2) {
      const debounceTimer = setTimeout(() => {
        getResultsByAPI();
      }, 500);

      return () => {
        clearTimeout(debounceTimer);
      };
    }
  }, [searchTerm]);

  const getResultsByAPI = () => {
    setLoading(true);
    let configAll = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/allsearch?text=${searchTerm}`,
      headers: {},
    };

    axios
      .request(configAll)
      .then((response) => {
        // console.log(response);
        // console.log(response?.data?.data);
        setTempCurrentPage(response?.data?.current_page);
        setSearch(response?.data?.data);
        dispatch(
          setFilteredProducts({
            total: response.data.total,
          })
        );
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // console.log(tempCurrentPage);
  // console.log(searchTerm);
  // console.log(search);

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <div className="search-box" ref={searchRef}>
          <input
            className="search-input"
            type="search"
            placeholder={`${t("Search by Reference Number or Product")}`}
            value={searchTerm}
            onChange={handleChange2}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />

          <button
            type="submit"
            className="search-btn"
            onClick={moveToSearchScreen}
          >
            <BsSearch style={{ color: "#b58f6c" }} />
          </button>

          {searchTerm.length !== 0 &&
            (loading === true ? (
              <div className="result-box mt-5 rounded NewSpinner">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border New_Color" role="status">
                    <span className="sr-only New_Color"></span>
                  </div>
                </div>
              </div>
            ) : search ? (
              <>
                <div className="result-box mt-5 rounded">
                  {search.length === 0 ? (
                    <div className="py-3 text-center">No products found</div>
                  ) : (
                    <>
                      {search.map((product) => {
                        return !product.hasOwnProperty("product") ? (
                          product.root ? (
                            <Link
                              key={product.id}
                              to={
                                "/product-category/" +
                                product.slug +
                                "/" +
                                product.id
                              }
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <QuickResultTileCategory
                                quickResultItem={product}
                                subCat={false}
                              />
                            </Link>
                          ) : (
                            <Link
                              key={product.id}
                              to={`/category/specific/${product.slug}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <QuickResultTileCategory
                                quickResultItem={product}
                                subCat={true}
                              />
                            </Link>
                          )
                        ) : (
                          <Link
                            key={product.id}
                            to={`/product/specific/${product.url_key}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <QuickResultTile quickResultItem={product} />
                          </Link>
                        );
                      })}

                      {totalFiltered > 9 ? (
                        <div
                          className="text-center ELPi-text-color my-1"
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                          onClick={moveToSearchScreen}
                        >{`SEE ALL RESULTS...(${totalFiltered})`}</div>
                      ) : null}
                    </>
                  )}
                </div>
              </>
            ) : null)}
        </div>
      </div>
    </>
  );
};

export default Searchbar;
