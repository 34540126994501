import React, { useEffect, useState } from 'react';
import GridViewComponent from '../../Components/GridViewComponent/GridViewComponent';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import Loader from '../../Components/Loader/Loader';
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent';
import './CategoryCompaniesScreen.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';

const ProductCompaniesScreen = () => {
  const { t } = useTranslation();
  const [allCompanies, setAllCompanies] = useState();
  const lang = useSelector((state) => state.lang.ELPiLang);

  const { category_name } = useParams();
  const { category_id } = useParams();

  const getCompaniesSlides = async (pathComponent) => {
    const res = await axios.get(`${BASE_URL}/${pathComponent}`);
    setAllCompanies(res?.data?.data);
    // console.log(res);
  };

  useEffect(() => {
    if (lang === 'fr') {
      getCompaniesSlides(
        `descendant-categories?parent_id=${category_id}&locale=fr`
      );
    } else {
      getCompaniesSlides(`descendant-categories?parent_id=${category_id}`);
    }
  }, [lang]);

  if (allCompanies === undefined) {
    return <Loader />;
  }

  if (allCompanies.length === 0) {
    return <EmptyComponent text={t('No Products Found')} />;
  }

  return (
    <>
      {allCompanies.length === 0 ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>
              {`${category_name.split('-').join(' ').toUpperCase()} - European
              Lorry Parts Industry`}
            </title>
            <meta property="og:title" content="Cart"></meta>
          </Helmet>
          <div className="page-container container">
            <div className="row">
              {allCompanies.map((company) => (
                <div
                  key={company.id}
                  className="col-lg-4 col-md-4 col-sm-6 col-6"
                >
                  <Link
                    to={
                      '/product-category/' +
                      category_name +
                      '/' +
                      category_id +
                      '/' +
                      company.slug +
                      '/' +
                      company.id
                    }
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <GridViewComponent item={company} />
                  </Link>
                  {/* <h1>hi</h1> */}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductCompaniesScreen;
