import React from "react";

const EmptyComponent = ({ text }) => {
  return (
    <div className="page-container">
      <div
        className="d-flex justify-content-center mt-5 mx-5"
        style={{
          borderTop: "1px solid #b58f6c",
          borderBottom: "1px solid #b58f6c",
        }}
      >
        <p className="py-4 text-center h5 ELPi-text-color">{text}</p>
      </div>
    </div>
  );
};

export default EmptyComponent;
