import React, { useState } from "react";
import "./CustomerSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";

const CustomerSlider = ({ customerSliderData }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <HiChevronDoubleLeft />,
    nextArrow: <HiChevronDoubleRight />,
    autoplay: true,
    autoplaySpeed: 5000,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       infinite: true,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       infinite: true,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //     },
    //   },
    // ],
  };

  // console.log(customerSliderData);

  return (
    <>
      <div className="container">
        <Slider {...settings} className="my-5">
          {customerSliderData?.map((slide, index) => (
            <div key={index} className="customer-slider-container">
              <div className="d-flex flex-column py-5">
                <div className="d-flex justify-content-center">
                  <img
                    className="customer-image pb-4"
                    src={slide.image_url}
                    alt={slide.id}
                  />
                </div>
                <div className="text-center px-5 pb-1">
                  <p style={{ color: "#b58f6c", fontWeight: "700" }}>
                    {slide.customer_name}
                  </p>
                </div>
                <div className="text-center px-5">
                  <p
                    className="testimonial-text"
                    style={{ fontWeight: "500", color: "#b58f6c" }}
                  >
                    {slide.description_arabic}
                  </p>
                </div>
                <div className="text-center px-5">
                  <p
                    className="testimonial-text"
                    style={{ fontWeight: "500", color: "#b58f6c" }}
                  >
                    {slide.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CustomerSlider;
