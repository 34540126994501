import React, { useEffect, useState } from 'react';
import GridViewComponent from '../../Components/GridViewComponent/GridViewComponent';
import { BASE_URL } from '../../Constants/Constants';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

const CompanyProductsDisplayScreen = () => {
  const [currentpage, setCurrentPage] = useState(0);
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState();

  const { category_slug } = useParams();
  const lang = useSelector((state) => state.lang.ELPiLang);

  const getProductsSlides = async (pathComponent) => {
    const res = await axios.post(`${BASE_URL}/${pathComponent}`);
    setAllProducts(res?.data);
  };

  useEffect(() => {
    if (lang === 'fr') {
      getProductsSlides(`category-slug?slug=${category_slug}&locale=fr`);
    } else {
      getProductsSlides(`category-slug?slug=${category_slug}`);
    }
  }, [lang]);

  if (allProducts === undefined) {
    return <Loader />;
  }

  if (allProducts.length === 0) {
    return <EmptyComponent text={t('No Products Found')} />;
  }

  const PER_PAGE = 9;

  function handlePageClick({ selected: selectedPage }) {
    // console.log("selectedPage", selectedPage);
    setCurrentPage(selectedPage);
  }
  const offset = currentpage * PER_PAGE;
  const pageCount = Math.ceil(allProducts.length / PER_PAGE);

  return (
    <>
      <Helmet>
        <title>
          {`${category_slug.split('-').join(' ').toUpperCase()} - European Lorry
          Parts Industry`}
        </title>
        <meta property="og:title" content="Cart"></meta>
      </Helmet>
      {allProducts.length === 0 ? (
        <Loader />
      ) : (
        <div className="page-container container">
          <div className="row">
            {allProducts.slice(offset, offset + PER_PAGE).map((product) => (
              <div
                key={product.id}
                className="col-lg-4 col-md-6 col-sm-6 col-12"
              >
                <Link
                  to={`/product/specific/${product.url_key}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <GridViewComponent item={product} />
                </Link>
              </div>
            ))}
          </div>
          {allProducts.length >= 9 ? (
            <ReactPaginate
              previousLabel={pageCount === 1 ? 'Disabled' : 'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages_pagination'}
              activeClassName={'active'}
              disabledClassName={'pagination_link--disabled'}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default CompanyProductsDisplayScreen;
