import * as Yup from "yup";
import zxcvbn from "zxcvbn";

export const enquiryFormSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid Email").required("This field is required"),
  phone: Yup.string().required("This field is required"),
  address: Yup.string().required("This field is required"),
  subject: Yup.string().required("This field is required"),
  message: Yup.string().nullable(),
  products: Yup.string().nullable(),
});

export const contactFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const signupFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const resetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  new_password: Yup.string()
    .required("New password is required")
    .min(12, "New password should be at least 12 characters long")
    .test(
      "password-strength",
      "New password is not strong enough",
      function (value) {
        const score = zxcvbn(value).score;
        return score > 2;
      }
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const billingAddressFormSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  country: Yup.string().required("Country is required"),
  street_address: Yup.string().required("Street address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postcode: Yup.string().required("Postcode is required"),
  phone: Yup.string().required("Phone is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const shippingAddressFormSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  country: Yup.string().required("Country is required"),
  street_address: Yup.string().required("Street address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postcode: Yup.string().required("Postcode is required"),
});

export const accountDetailsFormSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  new_password: Yup.lazy((value) => {
    if (value && value.length > 0) {
      return Yup.string()
        .min(12, "New password should be at least 12 characters long")
        .test(
          "password-strength",
          "New password is not strong enough",
          function (value) {
            const score = zxcvbn(value).score;
            return score > 2;
          }
        );
    }
    return Yup.string().nullable();
  }),
  confirm_password: Yup.string()
    .nullable()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});
