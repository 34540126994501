import React, { useState, useEffect } from "react";
import ContactUsDetails from "../../Components/ContactUsDetails/ContactUsDetails";
import MapAddress from "../../Components/MapAddress/MapAddress";
import ContactUsForm from "../../Components/ContactUsForm/ContactUsForm";
import { BASE_URL } from "../../Constants/Constants";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const ContactUsScreen = () => {
  const { t } = useTranslation();
  const [contactUsData, setContactUsData] = useState(undefined);
  const [showMap, setShowMap] = useState(0);
  const lang = useSelector((state) => state.lang.ELPiLang);

  const getContactUsData = (pathComponent) => {
    axios.get(`${BASE_URL}/${pathComponent}`).then((res) => {
      setContactUsData(res?.data?.Contact_Us_Data[0]);
      setShowMap(res?.data?.Contact_Us_Data[0]?.map_visibility);
    });
  };

  useEffect(() => {
    if (lang === "fr") {
      getContactUsData(`contactus-data-fr`);
    } else {
      getContactUsData(`contactus-data`);
    }
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>{`Contact - European Lorry Parts Industry`}</title>
        <meta
          property="og:description"
          content="Contact Us  ELPI&nbsp;GENERAL TRADING LLCP.O Box: 40934, Dubai, U.A.E   

GET IN TOUCH"
        ></meta>
      </Helmet>
      {!contactUsData ? (
        <Loader />
      ) : (
        <div className="page-container">
          <div className="container my-5">
            <div className="d-flex justify-content-center my-2">
              <p className="h1 ELPi-text-color">{t("contact_us")}</p>
            </div>
            <div className="contact-us-container my-2 row">
              <div className="col-lg-6 col-md-6 col-12 d-flex flex-column">
                <div className="mb-3">
                  <div className="mobile-padding">
                    <ContactUsDetails contactUsData={contactUsData} />
                  </div>
                </div>
                <div className="mobile-padding">
                  {showMap ? <MapAddress /> : null}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="mobile-padding">
                  <ContactUsForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsScreen;
