import React from "react";
import NavbarComponent from "./Components/Navbar/NavbarComponent";
import Footer from "./Components/Footer/Footer";
import HomeScreen from "./Screens/HomeScreens/HomeScreen";
import Error from "./Screens/ErrorScreen/Error";
import "./App.css";
import { createBrowserRouter, Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import LoginSignupScreen from "./Screens/LoginSignupScreen/LoginSignupScreen";
import LostPasswordScreen from "./Screens/LostPasswordScreen/LostPasswordScreen";
import AllCategoriesScreen from "./Screens/AllCategoriesScreen/AllCategoriesScreen";
import CategoryCompaniesScreen from "./Screens/CategoryCompaniesScreen/CategoryCompaniesScreen";
import CompanyProductsScreen from "./Screens/CompanyProductsScreen/CompanyProductsScreen";
import SingleProductScreen from "./Screens/SingleProductScreen/SingleProductScreen";
import AboutUsScreen from "./Screens/AboutUsScreen/AboutUsScreen";
import CartScreen from "./Screens/CartScreen/CartScreen";
import ContactUsScreen from "./Screens/ContactUsScreen/ContactUsScreen";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import AccountScreen from "./Screens/AccountScreen/AccountScreen";
import ProductDisplayScreen from "./Screens/SingleProductScreen/ProductDisplayScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen/ResetPasswordScreen";
import SearchProductsScreen from "./Screens/SearchProductsScreen/SearchProductsScreen";
import CompanyProductsDisplayScreen from "./Screens/CompanyProductsScreen/CompanyProductsDisplayScreen";

const App = () => {
  return (
    <div className="App">
      <ScrollToTop />
      <NavbarComponent />
      <div
        className="d-flex flex-column justify-content-between"
        style={{ height: "100vh" }}
      >
        <div>
          <Container className="app-container" fluid>
            <Outlet />
          </Container>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

//router
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <HomeScreen />,
      },
      {
        path: "/search/products/:query/:pageNo",
        element: <SearchProductsScreen />,
      },
      // {
      //   path: "/login",
      //   element: <LoginSignupScreen />,
      // },
      // {
      //   path: "/lost-password",
      //   element: <LostPasswordScreen />,
      // },
      // {
      //   path: "/reset-password",
      //   element: <ResetPasswordScreen />,
      // },
      {
        path: "/products",
        element: <AllCategoriesScreen />,
      },
      {
        path: "/product-category/:category_name/:category_id",
        element: <CategoryCompaniesScreen />,
      },
      {
        path: "/product-category/:category_name/:category_id/:company_name/:company_id",
        element: <CompanyProductsScreen />,
      },
      {
        path: "/product/:category_name/:category_id/:company_name/:company_id/:product_slug",
        element: <SingleProductScreen />,
      },
      {
        path: "/product/specific/:product_slug",
        element: <ProductDisplayScreen />,
      },
      {
        path: "/category/specific/:category_slug",
        element: <CompanyProductsDisplayScreen />,
      },
      {
        path: "/about-us",
        element: <AboutUsScreen />,
      },
      {
        path: "/cart",
        element: <CartScreen />,
      },
      {
        path: "/contact-us",
        element: <ContactUsScreen />,
      },
      // {
      //   path: "/my-account",
      //   element: <AccountScreen />,
      // },
    ],
  },
]);

export default appRouter;
