import React, { useState, useEffect } from 'react';
import './CoverComponent.css';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import { useSelector } from 'react-redux';

// const cover = require("../../Assets/Images/CoverImage/coverImage.jpg");

const CoverComponent = ({ coverAPIUrl }) => {
  const lang = useSelector((state) => state.lang.ELPiLang);
  // console.log(coverAPIUrl);
  const [coverData, setCoverData] = useState();

  const getCoverData = async () => {
    let res = await axios.get(coverAPIUrl?.url);
    // console.log(res);
    if (lang === 'fr') {
      // console.log("french-here");
      const slider = res?.data?.data.filter((slider) => {
        return slider.title === 'french-cover';
      });
      // console.log(slider);
      setCoverData(slider);
    } else if (lang === 'en') {
      // console.log("english-here");
      const slider = res?.data?.data.filter((slider) => {
        return slider.title === 'english-cover';
      });
      // console.log(slider);
      setCoverData(slider);
    }
    // console.log(res?.data?.data);
    // setCoverData(res);
  };

  useEffect(() => {
    getCoverData();
  }, []);

  return (
    <>
      {!coverData ? null : (
        <div className="cover-container">
          <img
            className="cover-img"
            src={coverData[0]?.image_url}
            alt="coverImg"
          />
        </div>
      )}
    </>
  );
};

export default CoverComponent;
