import React from "react";

const QuickResultTile = ({ quickResultItem }) => {
  // console.log(quickResultItem);
  return (
    <div className="quick-result-tile-container d-flex flex-row rounded">
      <div className="quick-result-tile-img">
        <img
          className="quick-result-item-image mx-2 my-2"
          src={quickResultItem?.images[0]?.original_image_url}
          alt={quickResultItem?.description}
        />
      </div>
      <div className="quick-result-tile-details d-flex flex-column justify-content-center">
        <div
          className="quick-result-tile-name"
          style={{ color: "#444444", fontSize: "15px", fontWeight: "700" }}
        >
          {quickResultItem?.name}
        </div>
        <div
          className="quick-result-tile-category"
          style={{ color: "#777777", fontSize: "12px", fontWeight: "500" }}
        >
          {quickResultItem?.description}
        </div>
      </div>
    </div>
  );
};

export default QuickResultTile;
