import React from "react";
import "./MapAddress.css";

const MapAddress = () => {
  return (
    <div className="company-address">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28863.968713013346!2d55.311566!3d25.270717!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f434af8a4e61f%3A0x5a0ec3f8be344aa5!2sELPI!5e0!3m2!1sen!2sus!4v1680170699138!5m2!1sen!2sus"
        style={{ border: 0 }}
        referrerPolicy="no-referrer-when-downgrade"
        title="company-address"
      ></iframe>
    </div>
  );
};

export default MapAddress;
