import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MissionStatement.css";

const MissionStatement = ({ aboutUsData }) => {
  // console.log(aboutUsData);
  const [heading, setHeading] = useState(aboutUsData[0]?.about_heading);
  const [para, setPara] = useState(aboutUsData[0]?.about_para);
  const [button, setButton] = useState(aboutUsData[0]?.button_text);

  return (
    <div className="container my-5 d-flex flex-column align-items-center">
      <div className="mission-heading h1 mb-3" style={{ color: "#b58f6c" }}>
        {heading}
      </div>
      <div className="my-4">
        <p className="h6 mission-text text-center" style={{ color: "#b58f6c" }}>
          {para}
        </p>
      </div>
      <div>
        <Link
          to="/about-us"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <button className="btn basic-btn my-2">{button}</button>
        </Link>
      </div>
    </div>
  );
};

export default MissionStatement;
