import React, { useState } from "react";
import { setLangToEnglish } from "../../ReduxStore/Store";
import { setLangToFrench } from "../../ReduxStore/Store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./LanguageToggle.css";

function LanguageToggle() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.ELPiLang);
  const [language, setLanguage] = useState(lang);

  const handleLanguageChange = (newLanguage) => {
    if (newLanguage === "en") {
      dispatch(setLangToEnglish());
      window.location.reload();
    }
    if (newLanguage === "fr") {
      dispatch(setLangToFrench());
      window.location.reload();
    }
    setLanguage(newLanguage);
  };

  return (
    <div className="language-toggle d-flex">
      <p className="ELPi-text-color me-2 mt-1 hide-in-mobile">Language:</p>
      <div id="lang-switch">
        <abbr title="French">
          <img
            src="https://cdn3.iconfinder.com/data/icons/finalflags/256/France-Flag.png"
            class={`fr ${language === "fr" ? "active-flag" : null}`}
            alt="fr"
            onClick={() => handleLanguageChange("fr")}
          />
        </abbr>
        <abbr title="English">
          <img
            src="https://cdn3.iconfinder.com/data/icons/finalflags/256/United-Kingdom-flag.png"
            class={`en ${language === "en" ? "active-flag" : null}`}
            alt="en"
            onClick={() => handleLanguageChange("en")}
          />
        </abbr>
      </div>
    </div>
  );
}

export default LanguageToggle;
