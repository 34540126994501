import React, { useState, useEffect } from 'react';
import './ContactUsForm.css';
import { useFormik } from 'formik';
import { contactFormSchema } from '../../YupSchemas/FormValidation';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import SmallLoader from '../Loader/SmallLoader';
import { useTranslation } from 'react-i18next';

const ContactUsForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
      validationSchema: contactFormSchema,
      validateOnBlur: false,
      onSubmit: (values, action) => {
        const params = new URLSearchParams();
        params.append('name', values.name);
        params.append('email', values.email);
        params.append('phone', values.phone);
        params.append('message_title', values.subject);
        params.append('message_body', values.message);
        sendUserContactDetails(params, action);
      },
    });

  const sendUserContactDetails = (params, action) => {
    setIsLoading(true);
    setSuccess(undefined);
    setError(undefined);
    axios
      .post(`${BASE_URL}/contactus`, params)
      .then((res) => {
        // console.log(res?.data?.message);
        setSuccess(res?.data?.message);
        setIsLoading(false);
        action.resetForm();
      })
      .catch((error) => {
        // console.log(error?.message);
        setError(error?.message);
        setIsLoading(false);
      });
  };

  // console.log(success);
  // console.log(errors);

  useEffect(() => {
    if (success || error) {
      setTimeout(() => {
        setSuccess(undefined);
        setError(undefined);
      }, 20000);
    }
  }, [success, error]);

  const handleResetMessage = () => {
    setSuccess(undefined);
    setError(undefined);
  };

  return (
    <div className="get-in-touch-form px-4 py-4 my-3">
      <div className="mb-4">
        <p className="h4" style={{ color: '#ffffff', fontWeight: '600' }}>
          {t('get_in_touch')}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group my-4">
          <input
            type="text"
            name="name"
            className="form-control form-input-contact py-2 px-3"
            placeholder={t('name')}
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            onClick={handleResetMessage}
            autofocus
          />
          {touched.name && errors.name ? (
            <p className="mx-3 pt-1 form-error" style={{ color: '#8B0000' }}>
              {errors.name}
            </p>
          ) : null}
        </div>
        <div className="form-group my-4">
          <input
            type="email"
            name="email"
            className="form-control form-input-contact py-2 px-3"
            placeholder={t('email_address')}
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            onClick={handleResetMessage}
            autofocus
          />
          {touched.email && errors.email ? (
            <p className="mx-3 pt-1 form-error" style={{ color: '#8B0000' }}>
              {errors.email}
            </p>
          ) : null}
        </div>
        <div className="form-group my-4">
          <input
            type="tel"
            name="phone"
            className="form-control form-input-contact py-2 px-3"
            placeholder={t('phone_number')}
            onChange={handleChange}
            value={values.phone}
            onBlur={handleBlur}
            onClick={handleResetMessage}
            autofocus
          />
        </div>
        <div className="form-group my-4">
          <input
            type="text"
            name="subject"
            className="form-control form-input-contact py-2 px-3"
            placeholder={t('subject')}
            onChange={handleChange}
            value={values.subject}
            onBlur={handleBlur}
            onClick={handleResetMessage}
            autofocus
          />
        </div>
        <div className="form-group my-4">
          <textarea
            name="message"
            className="form-control form-input-contact py-2 px-3"
            placeholder={t('message')}
            rows="4"
            onChange={handleChange}
            value={values.message}
            onBlur={handleBlur}
            onClick={handleResetMessage}
            autofocus
          ></textarea>
        </div>
        <div>
          <button
            type="submit"
            className="btn contact-btn my-2 py-3"
            style={{ fontWeight: 600 }}
          >
            {t('submit')}
          </button>
        </div>
      </form>
      <div className="d-flex justify-content-center align-items-center mt-3">
        {isLoading ? (
          <SmallLoader />
        ) : success ? (
          <p style={{ color: 'green' }}>{success}</p>
        ) : error ? (
          <p style={{ color: '#8B0000' }}>{error}</p>
        ) : null}
      </div>
    </div>
  );
};

export default ContactUsForm;
